import type { Maybe } from '@@types/helpers'
import type StoreRoot from '@app/stores/StoreRoot'
import * as React from 'react'

export interface IContextStores {
  storeRoot: Maybe<StoreRoot>
}

const ContextStores = React.createContext<IContextStores>({ storeRoot: null })

export default ContextStores
