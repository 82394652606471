import ContextStores from '@app/contexts/ContextStores'
import { appRoutes } from '@app/routes'
import AppRouter from '@libs/Router/AppRouter'
import type { AppRouterClient } from '@libs/Router/types'
import * as React from 'react'

/**
 * Return the router.
 */
export function useAppRouter(): AppRouterClient {
  const value = React.useContext(ContextStores)

  const appRouter =
    (value.storeRoot && value.storeRoot.appRouter) ||
    // when the root store is not available (server/application error)
    new AppRouter(appRoutes)

  return appRouter
}
