import { ContainerFlex } from '@app/components/Container'
import * as React from 'react'

interface IContainerSpinnerProps {
  className?: string
  children?: React.ReactNode
}

const ContainerSpinner: React.FC<IContainerSpinnerProps> = props => {
  return (
    <ContainerFlex
      name="ContainerSpinner"
      justifyContent="center"
      alignItems="center"
      items={[props.children]}
    />
  )
}

export default ContainerSpinner
