import type { IRouteDefinition, IRouteSpecs } from '@libs/Router/types'

/**
 * Express middlewares (Kapteyn server) routes.
 */

export enum ServerRouteName {
  'Root' = 'Root',

  'Middleware' = 'Middleware',

  'MiddlewareAuth' = 'MiddlewareAuth',
  'MiddlewareAuth_Login' = 'MiddlewareAuth_Login',
  'MiddlewareAuth_Logout' = 'MiddlewareAuth_Logout',
  'MiddlewareAuth_ResetPassword' = 'MiddlewareAuth_ResetPassword',
  'MiddlewareAuth_Providers' = 'MiddlewareAuth_Providers',
  'MiddlewareAuth_SAML_Redirect' = 'MiddlewareAuth_SAML_Redirect',
  'MiddlewareAuth_SAML_Login' = 'MiddlewareAuth_SAML_Login',
  'MiddlewareAuth_SAML_ExportMetadata' = 'MiddlewareAuth_SAML_ExportMetadata',

  'MiddlewareEula' = 'MiddlewareEula',
  'MiddlewareEula_License' = 'MiddlewareEula_License',

  'MiddlewareGraphQL' = 'MiddlewareGraphQL',

  'MiddlewareAPI' = 'MiddlewareAPI',
  'MiddlewareAPI_About' = 'MiddlewareAPI_About',
  'MiddlewareAPI_License_ProductAssociation' = 'MiddlewareAPI_License_ProductAssociation',
  'MiddlewareAPI_Export_Profiles_Checkers' = 'MiddlewareAPI_Export_Profiles_Checkers',
  'MiddlewareAPI_Profiles_Attacks_Export' = 'MiddlewareAPI_Profiles_Attacks_Export',

  'MiddlewareSAML' = 'MiddlewareSAML',
  'MiddlewareSAML_GenerateCertificate' = 'MiddlewareSAML_GenerateCertificate',

  'MiddlewareAssets' = 'MiddlewareAssets',
  'MiddlewareAssets_Images' = 'MiddlewareAssets_Images',
  'MiddlewareAssets_Icons' = 'MiddlewareAssets_Icons',
  'MiddlewareAssets_Videos' = 'MiddlewareAssets_Videos',
  'MiddlewareAssets_Fonts' = 'MiddlewareAssets_Fonts',

  'MiddlewareI18nLocale' = 'MiddlewareI18n',

  'MiddlewarePDF' = 'MiddlewarePDF',

  'MiddlewareReportingCenterReports' = 'MiddlewareReportingCenterReports',
  'MiddlewareReportingCenterReports_Metadata' = 'MiddlewareReportingCenterReports_Metadata',

  'MiddlewareExportCSV' = 'MiddlewareExportCSV',

  'MiddlewareUpload' = 'MiddlewareUpload',

  'MiddlewarePDF_Debug' = 'MiddlewarePDF_Debug',

  'MiddlewareIoA' = 'MiddlewareIoA',
  'MiddlewareIoA_Script' = 'MiddlewareIoA_Script',
  'MiddlewareIoA_Configuration' = 'MiddlewareIoA_Configuration',

  'MiddlewareCloudStatistics' = 'MiddlewareCloudStatistics',
  'MiddlewareServerProperties' = 'MiddlewareServerProperties',

  'MiddlewareTenableServicesProxy' = 'MiddlewareTenableServicesProxy',
  'MiddlewareTenableServicesProxy_ApiKeys' = 'MiddlewareTenableServicesProxy_ApiKeys',

  'MiddlewareTenableGlobalInventoryServicesProxy' = 'MiddlewareTenableGlobalInventoryServicesProxy',

  'MiddlewareFeatureToggles' = 'MiddlewareFeatureToggles'
}

interface IRootRouteDefinition extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.Root
  parameters: {}
}

interface IMiddlewareRouteDefinition extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.Middleware
  parameters: {}
}

interface IMiddlewareAuthRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAuth
  parameters: {}
}

interface IMiddlewareAuthLoginRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAuth_Login
  parameters: {}
}

interface IMiddlewareAuthLogoutRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAuth_Logout
  parameters: {}
}

interface IMiddlewareAuthResetPasswordRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAuth_ResetPassword
  parameters: {}
}

interface IMiddlewareAuthProvidersRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAuth_Providers
  parameters: {}
}

interface IMiddlewareAuthSAMLRedirectRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAuth_SAML_Redirect
  parameters: {}
}

interface IMiddlewareAuthSAMLLoginRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAuth_SAML_Login
  parameters: {}
}

interface IMiddlewareAuthSAMLExportMetadataRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAuth_SAML_ExportMetadata
  parameters: {}
}

interface IMiddlewareEulaRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareEula
  parameters: {}
}

interface IMiddlewareEulaLicenseRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareEula_License
  parameters: {}
}

interface IMiddlewareGraphQLRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareGraphQL
  parameters: {}
}

interface IMiddlewareAPIRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAPI
  parameters: {}
}

interface IMiddlewareAPIAboutRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAPI_About
  parameters: {}
}

interface IMiddlewareAPIExportProfilesCheckersRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAPI_Export_Profiles_Checkers
  parameters: {}
}

interface IMiddlewareAPIProfilesAttacksExportRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAPI_Profiles_Attacks_Export
  parameters: {}
}

interface IMiddlewareProductAssociationRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAPI_License_ProductAssociation
  parameters: {}
}

interface IMiddlewareSAMLRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareSAML
  parameters: {}
}

interface IMiddlewareSAMLGenerateCertificateRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareSAML_GenerateCertificate
  parameters: {}
}

interface IMiddlewareAssetsRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAssets
  parameters: {}
}

interface IMiddlewareAssetsImagesRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAssets_Images
  parameters: {
    image: string
  }
}

interface IMiddlewareAssetsIconsRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAssets_Icons
  parameters: {
    icon: string
  }
}

interface IMiddlewareAssetsVideosRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAssets_Videos
  parameters: {
    video: string
  }
}

interface IMiddlewareAssetsFontsRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareAssets_Fonts
  parameters: {
    font: string
  }
}

interface IMiddlewareI18nRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareI18nLocale
  parameters: {}
}

interface IMiddlewarePDFRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewarePDF
  parameters: {}
}

interface IMiddlewareReportingCenterReportsRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareReportingCenterReports
  parameters: {}
}

interface IMiddlewareReportingCenterReportsMetadataRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareReportingCenterReports_Metadata
  parameters: {}
}

interface IMiddlewareExportCSVRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareExportCSV
  parameters: {}
}

interface IMiddlewareUploadRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareUpload
  parameters: {}
}

interface IMiddlewareUploadLicenseRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareEula_License
  parameters: {}
}

interface IMiddlewarePDFDebugPageRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewarePDF_Debug
  parameters: {}
}

interface IMiddlewareAttackTypeConfigurationRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareIoA
  parameters: {}
}

interface IMiddlewareAttackTypeConfigurationGenerateGPORouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareIoA_Script
  parameters: {}
}

interface IMiddlewareAttackTypeConfigurationIoAConfigRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareIoA_Configuration
}

interface IMiddlewareCloudStatisticsRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareCloudStatistics
  parameters: {}
}

interface IMiddlewareServerPropertiesRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareServerProperties
  parameters: {}
}

interface IMiddlewareFeatureTogglesRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareFeatureToggles
  parameters: {}
}

interface IMiddlewareTenableServicesProxyRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareTenableServicesProxy
  parameters: {}
}

interface IMiddlewareTenableServicesProxyApiKeysRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareTenableServicesProxy_ApiKeys
  parameters: {}
}

interface IMiddlewareTenableGlobalInventoryServicesProxyRouteDefinition
  extends IRouteDefinition<ServerRouteName> {
  routeName: ServerRouteName.MiddlewareTenableGlobalInventoryServicesProxy
  parameters: {}
}

export type ServerRoute =
  | IRootRouteDefinition
  | IMiddlewareRouteDefinition
  | IMiddlewareAuthRouteDefinition
  | IMiddlewareAuthLoginRouteDefinition
  | IMiddlewareAuthLogoutRouteDefinition
  | IMiddlewareAuthResetPasswordRouteDefinition
  | IMiddlewareAuthProvidersRouteDefinition
  | IMiddlewareAuthSAMLRedirectRouteDefinition
  | IMiddlewareAuthSAMLLoginRouteDefinition
  | IMiddlewareAuthSAMLExportMetadataRouteDefinition
  | IMiddlewareEulaRouteDefinition
  | IMiddlewareEulaLicenseRouteDefinition
  | IMiddlewareGraphQLRouteDefinition
  | IMiddlewareAPIRouteDefinition
  | IMiddlewareAPIAboutRouteDefinition
  | IMiddlewareAPIExportProfilesCheckersRouteDefinition
  | IMiddlewareAPIProfilesAttacksExportRouteDefinition
  | IMiddlewareSAMLRouteDefinition
  | IMiddlewareSAMLGenerateCertificateRouteDefinition
  | IMiddlewareAssetsRouteDefinition
  | IMiddlewareAssetsImagesRouteDefinition
  | IMiddlewareAssetsIconsRouteDefinition
  | IMiddlewareAssetsVideosRouteDefinition
  | IMiddlewareAssetsFontsRouteDefinition
  | IMiddlewareI18nRouteDefinition
  | IMiddlewarePDFRouteDefinition
  | IMiddlewareReportingCenterReportsRouteDefinition
  | IMiddlewareReportingCenterReportsMetadataRouteDefinition
  | IMiddlewareExportCSVRouteDefinition
  | IMiddlewareUploadRouteDefinition
  | IMiddlewareUploadLicenseRouteDefinition
  | IMiddlewarePDFDebugPageRouteDefinition
  | IMiddlewareAttackTypeConfigurationRouteDefinition
  | IMiddlewareAttackTypeConfigurationGenerateGPORouteDefinition
  | IMiddlewareAttackTypeConfigurationIoAConfigRouteDefinition
  | IMiddlewareServerPropertiesRouteDefinition
  | IMiddlewareCloudStatisticsRouteDefinition
  | IMiddlewareProductAssociationRouteDefinition
  | IMiddlewareFeatureTogglesRouteDefinition
  | IMiddlewareTenableServicesProxyRouteDefinition
  | IMiddlewareTenableServicesProxyApiKeysRouteDefinition
  | IMiddlewareTenableGlobalInventoryServicesProxyRouteDefinition

export const serverRoutes: Record<
  ServerRouteName,
  IRouteSpecs<ServerRouteName>
> = {
  [ServerRouteName.Root]: {
    routeName: ServerRouteName.Root,
    pathname: '/'
  },

  [ServerRouteName.Middleware]: {
    routeName: ServerRouteName.Middleware,
    pathname: '/w'
  },

  /* Authentication */

  [ServerRouteName.MiddlewareAuth]: {
    routeName: ServerRouteName.MiddlewareAuth,
    pathname: '/w/auth'
  },

  // following routes are relative to the middleware above
  [ServerRouteName.MiddlewareAuth_Login]: {
    routeName: ServerRouteName.MiddlewareAuth_Login,
    pathname: '/providers/:provider/login'
  },

  [ServerRouteName.MiddlewareAuth_Logout]: {
    routeName: ServerRouteName.MiddlewareAuth_Logout,
    pathname: '/logout'
  },

  [ServerRouteName.MiddlewareAuth_Providers]: {
    routeName: ServerRouteName.MiddlewareAuth_Providers,
    pathname: '/providers'
  },

  [ServerRouteName.MiddlewareAuth_ResetPassword]: {
    routeName: ServerRouteName.MiddlewareAuth_ResetPassword,
    pathname: '/providers/tenable/reset-password'
  },

  [ServerRouteName.MiddlewareAuth_SAML_Redirect]: {
    routeName: ServerRouteName.MiddlewareAuth_SAML_Redirect,
    pathname: '/providers/saml/redirect'
  },

  [ServerRouteName.MiddlewareAuth_SAML_Login]: {
    routeName: ServerRouteName.MiddlewareAuth_SAML_Login,
    pathname: '/providers/saml/login'
  },

  [ServerRouteName.MiddlewareAuth_SAML_ExportMetadata]: {
    routeName: ServerRouteName.MiddlewareAuth_SAML_ExportMetadata,
    pathname: '/providers/saml/metadata'
  },

  /* SAML */

  [ServerRouteName.MiddlewareSAML]: {
    routeName: ServerRouteName.MiddlewareSAML,
    pathname: '/w/saml'
  },

  // following routes are relative to the middleware above
  [ServerRouteName.MiddlewareSAML_GenerateCertificate]: {
    routeName: ServerRouteName.MiddlewareSAML_GenerateCertificate,
    pathname: '/generate-certificate/download'
  },

  /* GraphQL */

  [ServerRouteName.MiddlewareGraphQL]: {
    routeName: ServerRouteName.MiddlewareGraphQL,
    pathname: '/w/graphql'
  },

  /* Proxy API */

  [ServerRouteName.MiddlewareAPI]: {
    routeName: ServerRouteName.MiddlewareAPI,
    pathname: '/w/api'
  },

  // following routes are relative to the middleware above
  [ServerRouteName.MiddlewareAPI_About]: {
    routeName: ServerRouteName.MiddlewareAPI_About,
    pathname: '/about'
  },

  [ServerRouteName.MiddlewareAPI_License_ProductAssociation]: {
    routeName: ServerRouteName.MiddlewareAPI_License_ProductAssociation,
    pathname: '/license/product-association'
  },

  [ServerRouteName.MiddlewareAPI_Export_Profiles_Checkers]: {
    routeName: ServerRouteName.MiddlewareAPI_Export_Profiles_Checkers,
    pathname: '/export/profiles/:profileId/checkers/:checkerId'
  },

  [ServerRouteName.MiddlewareAPI_Profiles_Attacks_Export]: {
    routeName: ServerRouteName.MiddlewareAPI_Profiles_Attacks_Export,
    pathname: '/profiles/:profileId/attacks/export'
  },

  /* Tenable Services Proxy */

  [ServerRouteName.MiddlewareTenableServicesProxy]: {
    routeName: ServerRouteName.MiddlewareTenableServicesProxy,
    pathname: '/w/tenable-services-proxy'
  },

  /* Tenable Global Inventory Services Proxy */

  [ServerRouteName.MiddlewareTenableGlobalInventoryServicesProxy]: {
    routeName: ServerRouteName.MiddlewareTenableGlobalInventoryServicesProxy,
    pathname: '/w/tenable-gi-services-proxy'
  },

  // following routes are relative to the middleware above
  [ServerRouteName.MiddlewareTenableServicesProxy_ApiKeys]: {
    routeName: ServerRouteName.MiddlewareTenableServicesProxy_ApiKeys,
    pathname: '/apikeys'
  },

  /* Static assets */

  [ServerRouteName.MiddlewareAssets]: {
    routeName: ServerRouteName.MiddlewareAssets,
    pathname: '/w/assets'
  },

  // following routes are relative to the middleware above
  [ServerRouteName.MiddlewareAssets_Images]: {
    routeName: ServerRouteName.MiddlewareAssets_Images,
    pathname: '/images/:image'
  },

  [ServerRouteName.MiddlewareAssets_Icons]: {
    routeName: ServerRouteName.MiddlewareAssets_Icons,
    pathname: '/icons/:icon'
  },

  [ServerRouteName.MiddlewareAssets_Videos]: {
    routeName: ServerRouteName.MiddlewareAssets_Videos,
    pathname: '/videos/:video'
  },

  [ServerRouteName.MiddlewareAssets_Fonts]: {
    routeName: ServerRouteName.MiddlewareAssets_Fonts,
    pathname: '/fonts/:font'
  },

  /* Locatisation */

  [ServerRouteName.MiddlewareI18nLocale]: {
    routeName: ServerRouteName.MiddlewareI18nLocale,
    pathname: '/w/i18n/:locale'
  },

  /* PDF */

  [ServerRouteName.MiddlewarePDF]: {
    routeName: ServerRouteName.MiddlewarePDF,
    pathname: '/w/pdf'
  },

  // following routes are relative to the middleware above
  [ServerRouteName.MiddlewarePDF_Debug]: {
    routeName: ServerRouteName.MiddlewarePDF_Debug,
    pathname: '/debug'
  },

  /* Reports */

  [ServerRouteName.MiddlewareReportingCenterReports]: {
    routeName: ServerRouteName.MiddlewareReportingCenterReports,
    pathname: '/w/reports'
  },

  [ServerRouteName.MiddlewareReportingCenterReports_Metadata]: {
    routeName: ServerRouteName.MiddlewareReportingCenterReports_Metadata,
    pathname: '/metadata'
  },

  /* Export */

  [ServerRouteName.MiddlewareExportCSV]: {
    routeName: ServerRouteName.MiddlewareExportCSV,
    pathname: '/w/export/csv'
  },

  /* Uploads */

  [ServerRouteName.MiddlewareUpload]: {
    routeName: ServerRouteName.MiddlewareUpload,
    pathname: '/w/upload'
  },

  /* EULA / License */

  [ServerRouteName.MiddlewareEula]: {
    routeName: ServerRouteName.MiddlewareEula,
    pathname: '/w/eula'
  },

  // following routes are relative to the middleware above
  [ServerRouteName.MiddlewareEula_License]: {
    routeName: ServerRouteName.MiddlewareEula_License,
    pathname: '/license'
  },

  /* Attacks Settings */

  [ServerRouteName.MiddlewareIoA]: {
    routeName: ServerRouteName.MiddlewareIoA,
    pathname: '/w/ioa'
  },

  // following routes are relative to the middleware above
  [ServerRouteName.MiddlewareIoA_Script]: {
    routeName: ServerRouteName.MiddlewareIoA_Script,
    pathname: '/script'
  },

  [ServerRouteName.MiddlewareIoA_Configuration]: {
    routeName: ServerRouteName.MiddlewareIoA_Configuration,
    pathname: '/config'
  },

  /* Pendo init data (Proxy to the Tenable's WUG to get data for TUL) */

  [ServerRouteName.MiddlewareCloudStatistics]: {
    routeName: ServerRouteName.MiddlewareCloudStatistics,
    pathname: '/w/cloud-statistics'
  },

  /* Server Properties (Proxy to the Tenable's WUG to get data for TUL) */

  [ServerRouteName.MiddlewareServerProperties]: {
    routeName: ServerRouteName.MiddlewareServerProperties,
    pathname: '/w/server-properties'
  },

  /* Feature Toggles */

  [ServerRouteName.MiddlewareFeatureToggles]: {
    routeName: ServerRouteName.MiddlewareFeatureToggles,
    pathname: '/w/feature-toggles'
  }
}
