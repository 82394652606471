export enum LabelVariant {
  description = 'description',
  h1 = 'h1',
  h2 = 'h2',
  p = 'p',
  p_bold = 'p_bold',
  h3 = 'h3',
  h3Centered = 'h3Centered',
  tenableH3 = 'tenableH3',
  tenableLargeMenu = 'tenableLargeMenu',
  tenableSmallText = 'tenableSmallText',
  // old
  menuTitle = 'menuTitle',
  legacyMenuTitle = 'legacyMenuTitle',
  menuLink = 'menuLink',
  legacyMenuLink = 'legacyMenuLink',
  headline1 = 'headline1',
  subtitle1 = 'subtitle1',
  caption = 'caption',
  error = 'error'
}
