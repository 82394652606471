import * as React from 'react'
import styled from 'styled-components'

interface ILabelBreakableProps {
  className?: string
  children: React.ReactNode
}

const LabelBreakable: React.FC<ILabelBreakableProps> = props => {
  return (
    <div data-name="LabelBreakable" className={props.className}>
      {props.children}
    </div>
  )
}

export default styled(LabelBreakable)`
  word-wrap: break-word;
  word-break: break-word;
`
