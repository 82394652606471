import { lighten, darken } from 'polished'

let zIndexBase = 1

/**
 * Global ZIndexes.
 * Order variables like containers have to be stacked.
 */

// navigation is normally positionned but have a larger zIndex of bladesContainer
// to be above blades overlays
export const zIndexBlade = zIndexBase++
export const zIndexHeaderBreadcrumbCrumb = zIndexBase++
export const zIndexNavigation = zIndexBase++
export const zIndexDrawerVerticalContainerOverlay = zIndexBase++
export const zIndexDrawerVerticalContainer = zIndexBase++
export const zIndexDrawer = zIndexBase++
export const zIndexDrawerContainerOverlay = zIndexBase++
export const zIndexDrawerContainer = zIndexBase++
export const zIndexHeadBar = zIndexBase++
export const zIndexAlertsPlaceholder = zIndexBase++
export const zIndexTul = 1000
export const zIndexLabelAppRibbon = zIndexTul + 1

// Margins / Paddings

export const marginExtraSmall = '2px'
export const marginVeryVerySmall = '3px'
export const marginVerySmall = '5px'
export const marginSmall = '10px'
export const marginSmaller = '15px'
export const marginDefault = '20px'
export const marginMedium = '30px'
export const marginLarge = '40px'
export const marginExtraLarge = '60px'

export const paddingVeryVerySmall = '3px'
export const paddingVerySmall = '5px'
export const paddingSmall = '7px'
export const paddingDefault = '10px'
export const paddingMedium = '15px'
export const paddingLarge = '20px'
export const paddingExtraLarge = '30px'
export const paddingXXL = '40px'
export const paddingXXXL = '60px'

// Colors

export const colorBlack001 = '#000000'
export const colorBlack010 = 'rgba(0, 0, 0, 0.5)'

export const colorWhite = '#FFFFFF'
export const colorWhiteAlt = '#F7F8FA'

export const colorGrey001 = '#f5f5f5'
export const colorGrey005 = '#dadada'
export const colorGrey010 = '#d9d9d9'
export const colorGrey015 = '#aaaaaa'
export const colorGrey030 = '#555555'

export const colorBlueBase = '#D9E8FB'
export const colorBlue001 = lighten(0.03, colorBlueBase)
export const colorBlue002 = darken(0.02, colorBlue001)
export const colorBlue005 = '#3C6B87'
export const colorBlue010 = '#1a425b'
export const colorBlue015 = '#1C2B45'
export const colorBlue017 = 'rgba(24, 144, 255, 0.2)'

export const colorCyan001 = 'rgba(115,179,235,1)'

export const colorBlueGray001 = '#333333'
export const colorBlueGray002 = '#353a43'
export const colorBlueGray005 = '#606B80'
export const colorBlueGray010 = '#7A8699'

export const colorBlueGray015 = '#9DA5B3'
export const colorBlueGray020 = '#CED3DB'
export const colorBlueGray025 = '#DCE0E6'
export const colorBlueGray030 = '#E9EBF0'
export const colorBlueGray035 = '#F7F8FA'
export const colorBlueGray040 = '#E7F3FC'

export const colorGreen005 = '#176817'
export const colorGreen020 = '#52DB52'
export const colorGreen030 = '#ACC1C4'

export const colorRed001 = '#FC522E'
export const colorRed010 = '#ff9696'

export const colorOrange001 = '#F48E0B'
export const colorOrange010 = '#f9ca9a'

export const colorYellow001 = '#EBDE17'

export const colorPurple010 = '#6f5fd7'

export const colorSuccessAntd = '#52c41a'
export const colorErrorAntd = '#ff4d4f'

// Attack path

export const colorAttackPathPrimary = '#D2D2FC'
export const colorAttackPathSecondary = '#686896'
export const colorAttackPathArrow = '#9B9BD0'
export const colorAttackPathBackgroundGroupedNodeCount = '#9B9BD0'

export const colorAttackPathDomainController = '#F16E74'
export const colorAttackPathReadOnlyDomainController = '#FFA6AD'
export const colorAttackPathDevice = '#C27AFF'
export const colorAttackPathDomain = '#D64646'
export const colorAttackPathEnas = '#70B04C'
export const colorAttackPathGmsa = '#7D7DFF'
export const colorAttackPathGPOFile = '#FF9968'
export const colorAttackPathGPOFolder = '#FFC0A2'
export const colorAttackPathGroup = '#7130ED'
export const colorAttackPathGroupPolicy = '#E47135'
export const colorAttackPathOrganizationalUnit = '#7A86A1'
export const colorAttackPathOther = '#B9C2D8'
export const colorAttackPathPkiCertificate = '#B5E092'
export const colorAttackPathSpecialIdentity = '#4F58FD'
export const colorAttackPathUser = '#A35DFB'
export const colorAttackPathUsp = '#B3AFFF'

// Texts

export const colorDefaultText = colorBlue015
export const colorLoginLayoutBackground = colorBlue015
export const colorBodyBackground = colorWhite
export const colorPPTXDefaultText = colorBlue015

// Selections

export const colorSelected01 = colorBlue001
export const colorSelected02 = colorBlue002

// Form

export const colorInputDisabled = colorGrey001

// Shadows

export const shadowLarge = '0px 6px 19px -4px rgba(0,0,0,0.75)'
export const shadowMainMenu = `3px 0px 5px -2px ${colorBlueGray015}`
export const shadowThin = '0px 0px 3px 0px rgba(0,0,0,0.30);'
export const shadowBlueAntd = `0 0 0 2px ${colorBlue017}`
export const shadowToggleableRow = `0px 0px 2px 1px ${colorCyan001}`

// Containers

export const containerBorderRadius = '5px'
export const containerSmallBorderRadius = '3px'
export const containerFooterHeight = '51px'

// Header

export const headerBackgroundColor = colorBlue015
export const headerHeight = '56px'

// Blades

export const bladesLeftOffset = '100px'
export function bladesOverlayBackground(inPosition: boolean) {
  return `rgba(45, 45, 45, ${inPosition ? 0.1 : 0});`
}
export const bladesBoxShadow = `-5px 0px 5px -3px ${colorGrey015}`

// Drawer

export const drawerFooterBoxShadow = '0px -3px 10px rgba(0, 0, 0, 0.05)'

// Typography

export const textSizeGlobal = '13px'
export const textDefaultColor = colorBlue015
export const textSizeExtraExtraLarge = '2em'
export const textSizeExtraLarge = '1.6em'
export const textSizeVeryLarge = '1.2em'
export const textSizeLarge = '1.1em'
export const textSizeNormal = '1em'
export const textSizeSmall = '0.9em'
export const textSizeVerySmall = '0.75em'

// Text SVG
export const textSVGSizeExtraLarge = '20px'
export const textSVGSizeLarge = '14px'
export const textSVGSizeNormal = '11px'
export const textSVGSizeSmall = '8px'
export const textSVGSizeExtraSmall = '7px'

// Font weight

export const fontWeightNormal = '400'
export const fontWeightBold = '600'

// Tags

export const fontMonospaceDefault =
  'font-family: "Source Code Pro"; font-size: 12px;'
export const fontTextAreaDefault = fontMonospaceDefault

// Icons & Images

export const iconSmallSize = '14px'
export const iconDefaultSize = '22px'

// Tooltips

export const tooltipDefaultWidth = '500px'

// Transitions

export const transitionOpacity = 'opacity 0.3s, background 0.3s'
export const transitionBackground = 'background-color 0.3s'

// Animations

export const smoothAnimation = `cubic-bezier(0.075, 0.82, 0.165, 1)`

// Breaking points

// https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
export const breakpointVerySmallViewPort = 576
export const breakpointSmallViewPort = 768
export const breakpointMediumViewPort = 992
export const breakpointLargeViewPort = 1200
export const breakpointExtraLargeViewPort = 1500

// Widths

export const productVersionWidth = '240px'
export const configurationCardsWidth = '670px'

/* Pages */

// Vulnerabilities

export const vulnerabilityCriticityHigh = colorRed010
export const vulnerabilityCriticityMedium = colorOrange010
export const vulnerabilityCriticityNone = colorGreen030

/* -------- Redesign -------- */

export const containerFooterHeightRedesign = '50px'

export const colorLighterGrayRedesign = '#E5E5F9'
export const colorWhiteAltRedesign = '#F2F2FD'
export const colorGrayAltRedesign = '#D4CCFD'
export const colorLighterVioletRedesign = '#C3C3EA'
export const colorBlackTransparentRedesign = 'rgba(39, 42, 51, 0.6)'

/**
 * @deprecated
 */
export enum BackgroundColor {
  primaryHover = '#F8F8FF',
  secondary = '#F0F0FF',
  more2 = '#262A34'
}

export const boxShadowRedesignLight = '0 0 10px rgba(213, 213, 233, 0.5)'
export const boxShadowRedesign = `0 12px 15px 0 ${colorLighterGrayRedesign}`

export const borderRadiusSmallRedesign = '3px'
export const borderRadiusRedesign = '5px'
export const borderRadiusLargeRedesign = '10px'
export const borderRadiusXLRedesign = '20px'

/* -------- New design (V2) -------- */

export const colorFadedBlueNewDesign = '#97CAFF'
export const colorGreyNewDesign = '#7C8AA3'
export const colorClearGreyNewDesign = '#D3D9E3'
export const gradientAngle = '135deg'

export function bladesOverlayBackgroundRedesign(inPosition: boolean) {
  return `rgba(41, 89, 166, ${inPosition ? 0.1 : 0});`
}

/**
 * Tenable.ad colors.
 */

export enum BrandColorV2 {
  blue1 = '#5FA6EF',
  blue2 = '#539FEC',
  blue3 = '#4697EA',
  blue4 = '#398FE7',
  blue5 = '#2E88E5',
  blue6 = '#2181E3',
  blue7 = '#157AE1',
  blue8 = '#0A73DF',
  blue9 = '#0365CA',
  gradientStart = '#B9DCFF',
  gradientEnd = '#026EDD',
  lightGray = '#C5CFE9',
  decorativeGray = '#E3EAF4'
}

export enum CTAColorV2 {
  primary = '#026EDD',
  primaryHover = '#52A2EF',
  secondary = '#D7EBFF',
  secondaryHover = '#ECF7FF',
  tertiary = '#FFFFFF',
  delete = '#FF8181',
  disable = '#EAEBF2'
}

export enum FontColorV2 {
  primary = '#545B92',
  secondary = '#959ABC',
  tertiary = '#7D98AC',
  link = '#026EDD',
  white = '#FFFFFF',
  // success and error
  green = '#3D702B',
  red = '#9E313E'
}

export enum BackgroundColorV2 {
  white = '#FFFFFF',
  lightGray = '#F7F9FC',
  tooltips = '#353A43',
  // success and error
  green = '#498334',
  greenLight = '#BDE8AF',
  red = '#C74757',
  redLight = '#FFC2CA'
}

/**
 * Tenable colors.
 */

export enum TenableColor {
  brand_d_blue = '#041E42'
}

export enum TenableTextColor {
  textBody = '#33363F',
  textAlt = '#616674'
}

export enum TenableBackgroundsColor {
  backgroundHover = '#F0F1F3',
  backgroundSelectedAlt = '#E4EBF5'
}

export enum TenableButtonsColor {
  backgroundButtonFloatingHover = '#E6E7EB',
  backgroundButtonFloatingSelected = '#D9DBE0'
}

export enum TenableBordersColor {
  borderBase = '#D9DBE0'
}
