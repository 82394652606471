import type { EnsureNonNullable } from '@@types/helpers'
import { isDefined } from '@libs/isDefined'

/**
 * Return the object `obj` without null or undefined values.
 */
export function filterNullOrUndefinedValues<O extends object>(
  obj: O,
  omitKeys?: Array<keyof O>
): EnsureNonNullable<O> {
  return Array.from(Object.entries(obj)).reduce(
    (acc, [key, value]) => {
      const isKeyOmitted = omitKeys && omitKeys.includes(key as keyof O)

      if (!isKeyOmitted && !isDefined(value)) {
        return acc
      }

      return {
        ...acc,
        [key]: value
      }
    },
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as EnsureNonNullable<O>
  )
}
