import type { Perhaps } from '@@types/helpers'
import { ensureArray } from '@libs/ensureArray'
import { isDefined, isDefinedAndNotEmptyString } from '@libs/isDefined'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import type { ARIARole } from 'aria-query'
import { kebabCase } from 'lodash'
import * as React from 'react'
import { useAppRouter } from './useAppRouter'

export interface ITestAttributeProps {
  // use for Cypress
  'data-test': string
  // use for Pendo
  'data-id': string
}

/**
 * Return a function that generates a data-test attribute used for e2e tests.
 */
export function useTestAttribute(mainAriaRole: ARIARole) {
  const appRouter = useAppRouter()

  function getValue(
    ariaRoles?: ARIARole | ARIARole[],
    ...labels: Array<Perhaps<string>>
  ): string {
    return [
      mainAriaRole,
      ...ensureArray(ariaRoles),
      ...labels.filter(isDefined).map(kebabCase)
    ]
      .filter(isDefinedAndNotEmptyString)
      .join('/')
  }

  function getPendoId(
    ariaRoles?: ARIARole | ARIARole[],
    ...labels: Array<Perhaps<string>>
  ): string {
    const isHistoryObjectDefined = isDefined(appRouter.historyObject())
    const currentRouteName = isHistoryObjectDefined
      ? appRouter.getCurrentRouteName()?.toLowerCase()
      : null
    return getValue(ariaRoles, currentRouteName, ...labels)
  }

  function getTestAttribute(
    ariaRoles?: ARIARole | ARIARole[],
    ...labels: Array<Perhaps<string>>
  ): string {
    return getValue(ariaRoles, ...labels)
  }

  const pendoAttributeProps = React.useCallback(
    (ariaRoles?: ARIARole | ARIARole[]) =>
      (label?: string): Partial<ITestAttributeProps> => {
        return filterEmptyProps({
          'data-id': getPendoId(ariaRoles, label)
        })
      },
    []
  )

  const pendoAttribute = React.useCallback(
    (ariaRoles?: ARIARole | ARIARole[]) =>
      (label?: string): string => {
        return getPendoId(ariaRoles, label)
      },
    []
  )

  const testAttributeProps = React.useCallback(
    (ariaRoles?: ARIARole | ARIARole[]) =>
      (label?: string, pendoSuffix?: string): Partial<ITestAttributeProps> => {
        return filterEmptyProps({
          'data-test': getTestAttribute(ariaRoles, label),
          'data-id': getPendoId(
            ariaRoles,
            label && pendoSuffix ? `${label}-${pendoSuffix}` : label
          )
        })
      },
    []
  )

  const testAttribute = React.useCallback(
    (ariaRoles?: ARIARole | ARIARole[]) =>
      (label?: string): string => {
        return getTestAttribute(ariaRoles, label)
      },
    []
  )

  return {
    testAttributeProps,
    testAttribute,
    pendoAttributeProps,
    pendoAttribute
  }
}
