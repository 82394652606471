export enum ValueType {
  string = 'string',
  regex = 'regex',
  float = 'float',
  integer = 'integer',
  boolean = 'boolean',
  date = 'date',
  object = 'object',
  sddl = 'sddl',
  arrayString = 'array/string',
  arrayRegex = 'array/regex',
  arrayInteger = 'array/integer',
  arrayBoolean = 'array/boolean',
  arraySelect = 'array/select',
  arrayObject = 'array/object',
  arrayCron = 'array/cron'
}
