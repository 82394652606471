import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { FontColorV2, TenableColor, TenableTextColor } from '@app/styles/consts'
import type { DSTheme } from '@design-system/styles/themes/types'
import { ensureArray } from '@libs/ensureArray'
import type { ARIARole } from 'aria-query'
import * as React from 'react'
import styled from 'styled-components'
import { LabelVariant } from './types'

function getFontSize(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.headline1:
      return '55px'
    case LabelVariant.h1:
      return '25px'
    case LabelVariant.tenableH3:
    case LabelVariant.tenableLargeMenu:
      return '18px'
    case LabelVariant.h2:
    case LabelVariant.subtitle1:
      return '17px'
    case LabelVariant.menuTitle:
    case LabelVariant.menuLink:
      return '14px'
    case LabelVariant.tenableSmallText:
      return '12px'
    case LabelVariant.caption:
      return '11px'
    case LabelVariant.h3:
    case LabelVariant.h3Centered:
      return '10px'
    default:
      return '13px'
  }
}

function getLineHeight(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.headline1:
      return '82px'
    case LabelVariant.h1:
      return '31.25px'
    case LabelVariant.menuTitle:
      return '20px'
    case LabelVariant.legacyMenuTitle:
      return '15px'
    case LabelVariant.subtitle1:
      return '25px'
    case LabelVariant.tenableH3:
    case LabelVariant.tenableLargeMenu:
      return '21.6px'
    case LabelVariant.h2:
      return '21.25px'
    case LabelVariant.caption:
      return '16px'
    case LabelVariant.tenableSmallText:
      return '13.8px'
    case LabelVariant.h3:
    case LabelVariant.h3Centered:
      return '12.5px'
    default:
      return '19.5px'
  }
}

function getLetterSpacing(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.headline1:
      return '-1.5px'
    case LabelVariant.subtitle1:
      return '0.5px'
    case LabelVariant.tenableLargeMenu:
      return '0.36px'
    case LabelVariant.h1:
    case LabelVariant.h2:
    case LabelVariant.p:
    case LabelVariant.p_bold:
    case LabelVariant.h3:
    case LabelVariant.h3Centered:
    case LabelVariant.tenableSmallText:
    case LabelVariant.caption:
      return '0px'
    default:
      return '0.25px'
  }
}

function getFontWeight(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.tenableH3:
    case LabelVariant.p_bold:
    case LabelVariant.h3:
    case LabelVariant.h3Centered:
      return '600' // Semi bold
    case LabelVariant.h2:
      return '800'
    default:
      return 'normal'
  }
}

function getFontColor(
  variant: LabelVariant = LabelVariant.p,
  theme: DSTheme
): string {
  switch (variant) {
    case LabelVariant.headline1:
    case LabelVariant.subtitle1:
    case LabelVariant.caption:
    case LabelVariant.h2:
      return FontColorV2.primary
    case LabelVariant.error:
      return consts.colorRed001
    case LabelVariant.p:
    case LabelVariant.p_bold:
      return theme.tokens['label/color/text']
    case LabelVariant.description:
    case LabelVariant.h3:
    case LabelVariant.h3Centered:
      return FontColorV2.secondary
    case LabelVariant.tenableH3:
      return TenableColor.brand_d_blue
    case LabelVariant.tenableLargeMenu:
      return TenableTextColor.textBody
    case LabelVariant.menuTitle:
      return FontColorV2.tertiary
    case LabelVariant.legacyMenuTitle:
      return FontColorV2.secondary
    default:
      return FontColorV2.primary
  }
}

function getFontFamily(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.tenableH3:
    case LabelVariant.tenableLargeMenu:
      return 'Barlow'
    case LabelVariant.tenableSmallText:
      return 'Helvetica, Arial, sans-serif'
    default:
      return 'Encode Sans Semi Expanded'
  }
}

function getTextTransform(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.h3:
    case LabelVariant.h3Centered:
    case LabelVariant.legacyMenuTitle:
      return 'uppercase'
    default:
      return 'inherit'
  }
}

function getFontStretch(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.tenableH3:
    case LabelVariant.tenableLargeMenu:
      return 'condensed'
    default:
      return 'normal'
  }
}

function getFlexGrow(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.h3Centered:
      return '1'
    default:
      return ''
  }
}

function getTextAlign(variant: LabelVariant = LabelVariant.p): string {
  switch (variant) {
    case LabelVariant.h3Centered:
      return 'center'
    default:
      return ''
  }
}

export interface ILabelProps {
  className?: string
  variant?: LabelVariant
  children?: React.ReactNode
  color?: string
  ariaRoles?: ARIARole | ARIARole[]
  label?: string
  labelledBy?: string
  style?: React.CSSProperties
}

const Label: React.FC<ILabelProps> = props => {
  const ariaRoles = ensureArray(props.ariaRoles)

  const [mainRole, ...otherRoles] = (ariaRoles.length && ariaRoles) || [
    'contentinfo'
  ]

  const { testAttributeProps } = useTestAttribute(mainRole)

  return (
    <span
      className={props.className}
      {...testAttributeProps(otherRoles || 'term')(props.labelledBy || 'label')}
      style={props.style}
    >
      {props.label || props.children}
    </span>
  )
}

export default styled(Label)`
  font-family: ${props => getFontFamily(props.variant)};
  font-weight: ${props => getFontWeight(props.variant)};
  font-size: ${props => getFontSize(props.variant)};
  line-height: ${props => getLineHeight(props.variant)};
  letter-spacing: ${props => getLetterSpacing(props.variant)};
  color: ${props => props.color || getFontColor(props.variant, props.theme)};
  text-transform: ${props => getTextTransform(props.variant)};
  font-stretch: ${props => getFontStretch(props.variant)};
  flex-grow: ${props => getFlexGrow(props.variant)};
  text-align: ${props => getTextAlign(props.variant)};

  .ant-tooltip-inner & {
    color: ${FontColorV2.white};
  }
`
