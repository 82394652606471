import { Label } from '@app/components/Labels/index'
import type { ILabelProps } from '@app/components/Labels/Label'
import { consts } from '@app/styles'
import type { BackgroundColor } from '@app/styles/consts'
import { Tag } from 'antd'
import * as React from 'react'
import styled from 'styled-components'

interface ILabelColorTagProps {
  className?: string
  style?: React.CSSProperties
  color?: BackgroundColor | string
  icon?: React.ReactNode
  label?: string
  children?: React.ReactNode
  labelProperties?: ILabelProps
}

const LabelColorTag: React.FC<ILabelColorTagProps> = props => {
  return (
    <Tag
      className={props.className}
      color={props.color}
      icon={props.icon}
      style={props.style}
    >
      <Label {...props.labelProperties}>{props.label ?? props.children}</Label>
    </Tag>
  )
}

export default styled(LabelColorTag)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${consts.paddingVerySmall};
  border: 0;
  border-radius: 3px;
  white-space: normal;
`
