import type { Perhaps } from '@@types/helpers'
import { isDefined } from './isDefined'

/**
 * Ensure that an array is returned if v is null or undefined.
 */
export function ensureArray<T>(v: Perhaps<T | T[]>): T[] {
  if (!isDefined(v)) {
    return []
  }

  if (Array.isArray(v)) {
    return v
  }

  // @ts-ignore Handle Mobx's observable that are "array-like" structure
  // @ts-ignore Uggly condition to handle Mobx's observable that are "array-like" structure
  if (typeof v !== 'string' && typeof v.length === 'number') {
    return v as unknown as T[]
  }

  return [v]
}

/**
 * Ensure that a Set is returned if s is null or undefined.
 */
export function ensureSet<T>(s: Perhaps<T | Set<T>>): Set<T> {
  if (!isDefined(s)) {
    return new Set<T>()
  }

  try {
    if ('has' in (s as object)) {
      return s as Set<T>
    }
    throw new Error()
  } catch (err) {
    return new Set<T>().add(s as T)
  }
}
