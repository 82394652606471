export enum Features {
  ACTIVITY_LOGS_USERS_FILTERING = 'activity-logs-users-filtering',
  ALERTING_THROUGH_RELAY = 'alerting-through-relay',
  ATTACK_PATH_DRAWER_SELECTION = 'attack-path-drawer-selection',
  ATTACK_PATH_PIN_HIGHLIGHTED_PATH = 'attack-path-pin-highlighted-path',
  ATTACK_PATH_TIER0 = 'attack-path-tier0',
  AZURE_AD_ADDON = 'azure-ad-addon',
  BETA_ON_AES_SCORE = 'beta-on-aes-score',
  CHECKER_C_AAD_PRIV_SYNC = 'checker-c-aad-priv-sync',
  CHECKER_C_AUTH_SILO = 'checker-c-auth-silo',
  CHECKER_C_CONFLICTED_OBJECTS = 'checker-c-conflicted-objects',
  CHECKER_C_DFS_MISCONFIG = 'checker-c-dfs-misconfig',
  CHECKER_C_DYNAMIC_UPDATES = 'checker-c-dynamic-updates',
  CHECKER_C_GUEST_ACCOUNT = 'checker-c-guest-account',
  CHECKER_C_MSA_COMPLIANCE = 'checker-c-msa-compliance',
  CHECKER_C_PROP_SET_SANITY = 'checker-c-prop-set-sanity',
  CHECKER_C_SHADOW_CREDENTIALS = 'checker-c-shadow-credentials',
  CHECKER_C_WSUS_HARDENING = 'checker-c-wsus-hardening',
  CLOSE_REOPEN_ALL_ATTACKS = 'close-reopen-all-attacks',
  COLLECT_ERRORS = 'collect-errors',
  COMPUTE_ACTIVE_USERS = 'compute-active-users',
  CSP_HEADER = 'csp-header',
  DASHBOARD_EXPORT = 'dashboard-export',
  DASHBOARD_QUICK_WIN = 'dashboard-quick-win',
  DATA_COLLECTION_LICENSE_SENDING = 'data-collection-license-sending',
  DRAWER_NEW_DESIGN = 'drawer-new-design',
  ENFORCE_LICENSE_VIOLATION_ON_PREM = 'enforce-license-violation-on-prem',
  FIRST_ACCOUNT_CUSTOMER_ONBOARDING = 'first-account-customer-onboarding',
  FOREST_AND_DOMAIN_SEARCH = 'forest-and-domain-search',
  GLOBAL_INVENTORY_IDENTITIES = 'global-inventory-identities',
  GRAPHQL_CSRF_PROTECTION = 'graphql-csrf-protection',
  HEALTH_CHECK = 'health-check',
  IDENTITY_EXPLORER_ORDERING = 'identity-explorer-ordering',
  INDICATOR_I_DC_PASSWORD_CHANGE = 'i-dc-password-change',
  INDICATOR_I_DNS_ENUMERATION = 'i-dns-enumeration',
  INDICATOR_I_UNAUTH_KERBEROASTING = 'i-unauth-kerberoasting',
  INDICATOR_I_ZEROLOGON = 'i-zerologon',
  IOA_EXPORT_SPEEDING = 'ioa-export-speeding',
  KAPTEYN_LEFT_NAVIGATION_UI = 'kapteyn-left-navigation-ui',
  KAPTEYN_SECURITY_ENGINE = 'kapteyn-security-engine',
  KAPTEYN_SECURITY_ENGINE_MILESTONE_2 = 'kapteyn-security-engine-m2',
  KAPTEYN_SECURITY_ENGINE_MILESTONE_3 = 'kapteyn-security-engine-m3',
  KAPTEYN_SECURITY_ENGINE_MILESTONE_4 = 'kapteyn-security-engine-m4',
  KAPTEYN_SECURITY_ENGINE_MILESTONE_5 = 'kapteyn-security-engine-m5',
  KAPTEYN_SECURITY_ENGINE_MILESTONE_6 = 'kapteyn-security-engine-m6',
  KAPTEYN_TUL_V6 = 'kapteyn-tul-v6',
  LDAP_AUTHENT_THROUGH_RELAY = 'ldap-authent-through-relay',
  LICENSE_EXPIRED_PROD_RESTRICT = 'license-expired-prod-restrict',
  LOCALES_INTERNAL_USE_ONLY = 'locales-internal-use-only',
  PENDO_INTEGRATION = 'pendo-integration',
  PLATFORM_STATUS_HEALTH_CHECK = 'platform-status-health-check',
  RBAC_ENTRAID_TENANT = 'rbac-entraid-tenant',
  REDIRECT_TAD_NOTIFICATIONS = 'redirect-tad-notifications',
  REDIRECT_TAD_RISK = 'redirect-tad-risk',
  REDIRECT_TAD_TRACE = 'redirect-tad-trace',
  REMOVE_ATTACKS = 'remove-attacks',
  REPORTING_CENTER = 'reporting-center',
  SENSITIVE_COLLECTION_DPAPI_KEY = 'sensitive-collection-dpapi-key',
  SENSITIVE_COLLECTION_USER_HASHES = 'sensitive-collection-user-hashes',
  SRLY_APIKEY_RENEWAL = 'srly-apikey-renewal',
  STATELESS_PASSWORD_ANALYSIS = 'stateless-password-analysis',
  TELEMETRY = 'telemetry',
  TENABLE_CLOUD_INCITATION_POPUP = 'tenable-cloud-incitation-popup',
  TENABLE_CLOUD_SENSITIVE_DATA = 'tenable-cloud-sensitive-data',
  TENABLE_IDENTITY_EXPOSURE_VISUALS = 'tenable-identity-exposure-visuals',
  TENABLE_UNIVERSAL_LAYOUT = 'tenable-universal-layout',
  TRACEABILITY_DASHBOARD = 'traceability-dashboard',
  TRAILFLOW_NEW_DESIGN = 'trailflow-new-design',
  TRIGGER_ASGARD_RESET = 'trigger-asgard-reset',
  TRIGGER_LICENSE_ENFORCEMENT_IOE_API = 'trigger-license-enforcement-ioe-api',
  WORKSPACE_APPSWITCHER_IN_TAD = 'workspace-appswitcher-in-tad',
}